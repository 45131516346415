.Tab {
  display: flex;
  align-items: center;
  padding: 4px;
  width: fit-content;
  border-radius: 40px;
  background-color: #f5f5f5;
  box-shadow: inset 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  margin: auto;
  font-family: 'Arial Light', sans-serif;
}

.Tab div {
  font-size: 0.8em;
  border-radius: 50px;
  color: #333;
  padding: 8px 24px;
  cursor: pointer;
  transition: all .2s linear;
}

.Tab div:hover{
  color: #000;
}

div.TabActive {
  background-color: #0076e4;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
}

div.TabActive:hover{
  color: #fff;
}
@import url('https://fonts.cdnfonts.com/css/arial');

/* 
Ok Ok .
*/



* {
  font-family: 'Arial', sans-serif;
}

h1,
h2,
h3,
p {
  margin: 0;
}

h1, h2, h3{
  font-weight: 600;
}

a.back {
  background-color: #000;

  position: absolute;
  top: 1em;
  right: 1em;
  color: #fff;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  padding-bottom: 12em;
}

.PricingAppHeader {
  font-family: 'Arial', sans-serif;
  width: fit-content;
  margin: 7.5em auto 3em;
  text-align: center;
  color:#333;
}

.PricingAppHeader h1 {

  margin-bottom: 0.5em;
  font-size: 3em;
  color: rgb(21, 19, 19);
}

.PricingAppHeader span {
  font-size: 0.85em;
}

@media screen and (max-width: 991px) {
  .PricingAppHeader h1 {
    margin-bottom: 0.5em;
    font-size: 2.75em;
    color: #333;
  }
}

@media screen and (max-width: 768px) {
  a.back {
    border-radius: 50px;
    font-size: 0.85em;
  }

  .PricingAppHeader h1 {
    margin-bottom: 0.5em;
    font-size: 2em;
  }
}

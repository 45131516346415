.Card {
  border-style: solid;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 8px;
  margin: 4em auto;
  padding: 32px;
  max-width: 350px;
  box-sizing: border-box;
  transition: box-shadow 0.1s linear;

  animation: cardAnimation 500ms forwards
}
@keyframes cardAnimation {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.Card:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.CardTitle {
  font-size: 1.45em;
  color: #1a1a1a;
}

.CardPrice span:nth-of-type(1) {
  font-weight: 600;
  font-size: 2.5em;
  color: #1a1a1a
  /* background-color: #343541; */
}

.CardPrice span:nth-of-type(2) {
  font-size: 1.25em;
  color: #333;
}

.CardPeriod {
  margin: 3em 0;
  color: #8d8d8d;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

button {
  background-color: rgb(53, 121, 246);
  outline: none;
  border: none;
  border-radius: 50px;
  color: #ffffff;
  text-transform: capitalize;
  width: 100%;
  max-width: 250px;
  font-size: 1em;
  height: 45px;
  cursor: pointer;
}
button:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media screen and (max-width: 990px) {
  .Card {
    width: 95%;
    max-width: 480px;
  }
}



.chat-input-box {
  margin: 110px;
}

.chat-input-textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  resize: none;
  color: white;
  background-color: #40414f;
  width: 50%;
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
  padding: 12px;
  font-size: 0.9em;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 35px;
}

.submit-svg {
  width: 16px;
  height: 16px;
}

.submit-button {
  border: 1px solid #40414f;
  border-radius: 10px;
  color: gray;
  background-color: #40414f;
  position: absolute;
  height: 6%;
  width: 7%;
  bottom: 50px;
  left: 70%;
  transform: translateX(-50%);
  transform: rotate(90deg);
  transition: all 150ms ease-in-out;
}

.submit-button:hover {
  background-color: #202123;
  border: 1px solid #202123;
}

.submit-button:disabled {
  background-color: #40414f;
  border: 1px solid #40414f;
  opacity: 0.1;
}

.mic-button {
  border: 1px solid #373942;
  border-radius: 10px;
  color: gray;
  background-color: #363842;
  position: absolute;
  bottom: 40px;
  left: 10%;

  transform: translateX(-50%);
  padding: 4px;
  padding-top: 6px;
}

.mic-button:hover {
  background-color: #202123;
  border: 1px solid #202123;
  transition: all 150ms ease-in;
}

.not-listening {
  color: rgb(255, 65, 65);
}

.listening {
  color: green;
}

@media (min-width: 1024px) {
  .chat-input-box {
    padding: 24px;
  }

  .chat-input-textarea {
    width: 50%;
    font-size: 1.25em;
    bottom: 62px;
    padding: 18px;
    padding-right: 60px;
  }

  .submit-button {
    bottom:   80px;
    left: 74%;

  }

  .mic-button {
    bottom: 85px;
    left: 17%;
  }

  .submit-svg {
    width: 20px;
    height: 20px;
  }
}

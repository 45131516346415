.wrapper {
    display: flex;
    align-items: center;
  }
  
  .text {
    flex: 1;
  }
  
  .plus-button {
    width: auto; 
    border-style: solid;
    border-width: 2px;
    border-color: goldenrod;
    border-radius: 4px;
    background-color: goldenrod;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    color: white;
    height: 30px;
    padding-bottom: 10px !important;
  }
  
  .plus-button span {
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: smaller;
    display: table-cell !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .plus-button:hover {
    border-color: gold;
    background-color: gold;
  }
  
  .plus-button:active {
    opacity: 0.2;
  }
  
  .caption-text {
    margin-top: 16px;
    color: whitesmoke;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
  
  @media (min-width: 1024px) {
    .plus-button span {
      padding-left: 1px;
      padding-right: 1px;
      display: flex;
      /* justify-content: center;
      padding-top: 0;
      padding-bottom: 0; */
    }
  
    .plus-button {
      padding: 1px;
      text-align: center;
      top: 1px;
      bottom: 1px;
      font-size: 12px;
    }
  }